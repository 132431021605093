import React, { useEffect } from "react"
import Prism from "prismjs"
import { graphql, Link } from "gatsby"
import { SEO } from "../components"
import { DefaultLayout } from "../layouts"
import { MarkdownPostInterface } from "../typings/post.interface"

// Prism
import "prism-themes/themes/prism-shades-of-purple.min.css" // Theme

// Prism Language
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-typescript"
import "prismjs/components/prism-python"
import "prismjs/components/prism-bash"
import "prismjs/components/prism-php-extras"

type PostDetailType = {
    frontmatter: MarkdownPostInterface
    html: string
    fields: {
        detail: {
            author: string
        }
    }
}

type PostProps = {
    data: {
        post: PostDetailType
    }
}

const PostTemplate = (props: PostProps) => {
    useEffect(() => {
        Prism.highlightAll()
    }, [])

    const post = props.data.post
    const { frontmatter } = post
    const { title, date } = frontmatter
    const { author } = post.fields.detail

    return (
        <>
            <SEO title={title} />
            <DefaultLayout>
                <article
                    itemScope
                    itemType="http://schema.org/Article"
                    className="my-10"
                >
                    <header className="break-words mb-10 text-center">
                        <div className="w-full mx-auto font-inconsolata">
                            <h6 className="font-semibold text-4xl mb-2">
                                {title}
                            </h6>
                            <p className="text-gray-400 text-lg">
                                Terbit pada tanggal {date}
                            </p>
                            <div className="text-gray-400 text-md">
                                Ditulis oleh:{" "}
                                <Link
                                    className="text-purple"
                                    to={`/members/${author}`}
                                >
                                    {author}
                                </Link>
                            </div>
                        </div>
                    </header>
                    <section
                        className="prose-lg prose-headings:font-inconsolata prose-headings:my-4 max-w-full break-words text-justify"
                        dangerouslySetInnerHTML={{ __html: post.html }}
                        itemProp="articleBody"
                    />
                </article>
            </DefaultLayout>
        </>
    )
}

export const BlogPostQuery = graphql`
    query BlogPostQuery($id: String!) {
        post: markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                slug
                date(formatString: "DD MMMM YYYY", locale: "id-ID")
                description
            }
            fields {
                detail {
                    author
                }
            }
        }
    }
`

export default PostTemplate
